import { createSlice } from "@reduxjs/toolkit";
const srSlice = createSlice({
  name: "SR",
  initialState: {
    SRList: null,
    coinMgtSRList: null,
    SRGraphDetails: null,
    SRTableDetails: null,
    SRGraphTimeDetails: null,
    SRGraphSaleDetails: null,
    SRCallConversion: null,
    SRAmountChartDetails: null,
    SRTimeTableDetails: null,
    SRPITableDetails:null,
    SRSaleTableDetails: null,
    SROrderChartDetails: null,
    srCoinDetails: null,
    singleSRCoinDetails: null
  },
  reducers: {
    getSRList: (state, action) => {
      state.SRList = action.payload;
    },
    getSRCoinDetails: (state, action) => {
      state.srCoinDetails = action.payload;
    },
    getSingleSRCoinDetails: (state, action) => {
      state.singleSRCoinDetails = action.payload;
    },
    getCoinMgtSRList: (state, action) => {
      state.coinMgtSRList = action.payload;
    },
    getSRGraphDetails: (state, action) => {
      state.SRGraphDetails = action.payload;
    },
    getSRGraphTimeDetails: (state, action) => {
      state.SRGraphTimeDetails = action.payload;
    },
    getSRGraphSaleDetails: (state, action) => {
      state.SRGraphSaleDetails = action.payload;
    },
    getSRCallConversion: (state, action) => {
      state.SRCallConversion = action.payload;
    },
    getSRAmountChartDetails: (state, action) => {
      state.SRAmountChartDetails = action.payload;
    },
    getSROrderChartDetails: (state, action) => {
      state.SROrderChartDetails = action.payload;
    },
    getSRTableDetails: (state, action) => {
      state.SRTableDetails = action.payload;
    },
    getSRTimeTableDetails: (state, action) => {
      state.SRTimeTableDetails = action.payload;
    },
    getSRSaleTableDetails: (state, action) => {
      state.SRSaleTableDetails = action.payload;
    },
    getSRPITableDetails: (state, action) => {
      state.SRPITableDetails = action.payload;
    },
  },
});

export default srSlice.reducer;
export const {
  getSRList,
  getSRGraphDetails,
  getSRTableDetails,
  getSRGraphTimeDetails,
  getSRGraphSaleDetails,
  getSRCallConversion,
  getSRAmountChartDetails,
  getSRTimeTableDetails,
  getSRSaleTableDetails,
  getSROrderChartDetails,
  getSRPITableDetails,
  getCoinMgtSRList,
  getSRCoinDetails,
  getSingleSRCoinDetails
} = srSlice.actions;
