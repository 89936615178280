import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Box,
  IconButton
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useEditTarget from "../../../hooks/useEditTarget";
import { Typography, Stack } from "@mui/material";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import coinData from "./data.json";
import Sidebar from "../../Sidebar";
import coinImage from "./coin.png";
import SingleSRExcelExport from "./SingleSRExcelExport";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router";
import useCoinMgtSRList from "../../../hooks/useCoinMgtSRList";
import dayjs from 'dayjs';
import { useValue } from "./valueProvider";
import useSingleSRCoinDetails from "../../../hooks/CoinManagement/useSingleSRCoinDetails";
import { toast, ToastContainer } from "react-toastify";
import TablePagination from '@mui/material/TablePagination';

const CoinManagementDetails = () => {
  const navigate = useNavigate();
  const [editModeRowId, setEditModeRowId] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const headerName = [
    { index: 0, name: "S. NO." },
    { index: 1, name: "Date" },
    { index: 2, name: "Fixed Salary" },
    { index: 3, name: "Call Stamp Coins" },
    { index: 4, name: "Time Stamp Coins" },
    { index: 5, name: "Sales Stamp Coins" },
    { index: 6, name: "Variable Salary" },
    { index: 7, name: "Petrol Allowance" },
    { index: 8, name: "Call Incentive Coins" },
    { index: 9, name: "Sales Incentive Coins" },
    { index: 10, name: "Incentives Sum" },
    { index: 11, name: "Coins Earned" },
  ];

  const months = [
    { value: "January", label: "January", index: 1 },
    { value: "February", label: "February", index: 2 },
    { value: "March", label: "March", index: 3 },
    { value: "April", label: "April", index: 4 },
    { value: "May", label: "May", index: 5 },
    { value: "June", label: "June", index: 6 },
    { value: "July", label: "July", index: 7 },
    { value: "August", label: "August", index: 8 },
    { value: "September", label: "September", index: 9 },
    { value: "October", label: "October", index: 10 },
    { value: "November", label: "November", index: 11 },
    { value: "December", label: "December", index: 12 },
  ];

  // const data = [
  //   {
  //     date: "1-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 50,
  //     total_call_target: 100,
  //     time_stamp_coins: 633,
  //     time_achieved: 4,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 5,
  //     total_sales_target: 180,
  //     variable_salary_coins: 434,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 762,
  //   },
  //   {
  //     date: "2-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 100,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 9,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 7,
  //     total_sales_target: 180,
  //     variable_salary_coins: 450,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "2 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "2k",
  //     incentives_sum: 44,
  //     coins_earned: 822,
  //   },
  //   {
  //     date: "3-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 150,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 15,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 12,
  //     total_sales_target: 180,
  //     variable_salary_coins: 454,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 782,
  //   },
  //   {
  //     date: "4-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 200,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 22,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 20,
  //     total_sales_target: 180,
  //     variable_salary_coins: 477,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 805,
  //   },
  //   {
  //     date: "5-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 250,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 27,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 22,
  //     total_sales_target: 180,
  //     variable_salary_coins: 422,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 750,
  //   },
  //   {
  //     date: "6-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 300,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 35,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 29,
  //     total_sales_target: 180,
  //     variable_salary_coins: 432,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 760,
  //   },
  //   {
  //     date: "7-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 350,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 44,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 31,
  //     total_sales_target: 180,
  //     variable_salary_coins: 455,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 783,
  //   },
  //   {
  //     date: "8-Oct",
  //     fixed_salary: 10,
  //     call_stamp_coins: 870,
  //     call_achieved: 400,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 56,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 35,
  //     total_sales_target: 180,
  //     variable_salary_coins: 421,
  //     petrol_coins: 150,
  //     petrol_allowance: "50 kms",
  //     call_incentive_calls_coins: 0,
  //     call_incentive_calls: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 749,
  //   },
  // ];
  const data= useSelector((state)=>state.sr.singleSRCoinDetails)
  const location = useLocation();
  // const { value, setValue } = useValue();
  const { selectedDate, userId } = location.state;
  const getAllCoinMgtSRList = useCoinMgtSRList();
  const srList = useSelector((state) => state.sr.coinMgtSRList);
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const currentState= useSelector((state)=>state.app.curretDropdownData)
  const tableData = useSelector((state) => state.performance.targetTableData);
  const getAllSingleSRCoinDetails= useSingleSRCoinDetails()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const month = selectedDate.$M + 1;
    const year = selectedDate.$y;
    const day= selectedDate.$D
    const specificMonth = selectedDate.$M;
    const specificYear = selectedDate.$y;
    let lastDayOfMonth;
    if (specificMonth === currentMonth && specificYear === currentYear) {
      lastDayOfMonth = today;
    } else {
      lastDayOfMonth = getLastDayOfMonth(specificYear, specificMonth);
    }
    const firstDayOfMonth = getFirstDayOfMonth(specificYear, specificMonth);
    setFormData((prev) => ({
      ...prev,
      start_date: firstDayOfMonth,
      end_date: lastDayOfMonth,
      monthYear: firstDayOfMonth
    }));
    const param = {
      user_id:userId,
      year:year,
      month:month,
      day: day,
      from: firstDayOfMonth?.getDate(),
      to: lastDayOfMonth?.getDate(),
      state_id: currentState?.state_id
    }
    getAllSingleSRCoinDetails(param)
  }, [userId, selectedDate]);

  // Function to get today's date
  const today = new Date();
  const currentMonth= today.getMonth()
  const currentYear= today.getFullYear()

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1); 
  };

  const getLastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0); 
  };

  // useEffect(() => {
  //   const specificMonth = selectedDate.$M;
  //   const specificYear = selectedDate.$y;
  //   let lastDayOfMonth;
  //   if (specificMonth === currentMonth && specificYear === currentYear) {
  //     lastDayOfMonth = today;
  //   } else {
  //     lastDayOfMonth = getLastDayOfMonth(specificYear, specificMonth);
  //   }

  //   const firstDayOfMonth = getFirstDayOfMonth(specificYear, specificMonth);
  //   setFormData((prev) => ({
  //     ...prev,
  //     start_date: firstDayOfMonth,
  //     end_date: lastDayOfMonth,
  //     monthYear: firstDayOfMonth
  //   }));
  // }, [selectedDate]);


  useEffect(() => {
    if (userId && srList) {
      const selectedUser = srList?.find((sr) => sr.user_id === userId);
      setFormData((prev) => ({
        ...prev,
        user_name: selectedUser?.first_name,
        user_id: selectedUser?.user_id,
      }));
    }
  }, [userId, srList]);

  

  const [formData, setFormData] = useState({
    user_name: '',
    user_id: '',
    monthYear: '',
    user_id: null,
    first_name: "",
    start_date: '',
    end_date: '',
  });

  console.log(formData?.user_name?.length, "length")
  useEffect(() => {
    // getTableData(formData);
  }, [currentzone, formData]);

  useEffect(() => {
    getAllCoinMgtSRList();
  }, [currentzone]);

  const getFirstAndLastDates = (month, year) => {
    const today = new Date();
    const isCurrentMonth = today.getMonth() + 1 === month && today.getFullYear() === year;
  
    // First day of the month
    const firstDate = new Date(year, month - 1, 1);
  
    // Last day of the month
    const lastDate = isCurrentMonth
      ? today // If current month, use today's date
      : new Date(year, month, 0); // Last day of the given month
  
    return {
      firstDate: firstDate,
      lastDate: lastDate,
    };
  };
  

  const handleDateChange = async(date, name) => {
    setPage(0)
    const month = date.getMonth()+1;
    const year= date.getFullYear();
    const day=date.getDate()
    const endDay= formData?.end_date?.getDate()
    const startDay= formData?.start_date?.getDate()
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: date
    }));

    if(name==="monthYear"){
      const result = getFirstAndLastDates(month, year);
      setFormData((prevData) => ({
        ...prevData,
        start_date: result?.firstDate,
        end_date: result?.lastDate
      }));

      let param= {
        user_id:formData?.user_id,
        year:year,
        month:month,
        from: result?.firstDate?.getDate(),
        to: result?.lastDate?.getDate(),
        state_id: currentState?.state_id
      };

      await getAllSingleSRCoinDetails(param)
    }else{
      let param= {
        user_id:formData?.user_id,
        year:year,
        month:month,
        state_id: currentState?.state_id,
      };

      const newMonth = formData?.monthYear?.getMonth() + 1;
      const newYear = formData?.monthYear?.getFullYear();
      if(newMonth!==month || newYear!==year){
        toast.error("Please change the month!")
        return;
      }
      if(name==="start_date"){
        param.from = day;
        param.to= endDay
      }else if(name==="end_date"){
        param.from = startDay;
        param.to= day
      }
       
      await getAllSingleSRCoinDetails(param)
    }
  };

  const handleChange= async (e)=>{
    setPage(0)
    const {name, value}=e.target
    const selectedUser= srList?.find((sr)=>sr.first_name===value)
      setFormData((prevData) => ({
        ...prevData,
        user_id: selectedUser?.user_id,
        user_name: value
      }));

      let param= {
        user_id: selectedUser?.user_id,
        year:formData?.start_date?.getFullYear(),
        month:formData?.start_date?.getMonth() + 1,
        from: formData?.start_date?.getDate(),
        to: formData?.end_date?.getDate(),
        state_id: currentState?.state_id
      };
      await getAllSingleSRCoinDetails(param)
  }

  console.log(formData,"handleChange")

  const handleEdit = (row) => {
    setEditModeRowId(row.utt_id);
    setEditedValues({
      utt_id: row.utt_id,
      month_no: row.month,
      sr_name: row.sr_name,
      sales_target: row.sales_target,
      time_stamp: row.time_stamp,
      call_stamp: row.call_stamp,
      outlets_coverage: row.outlets_coverage,
      lines_per_call: row.lines_per_call,
      petrol: row.petrol,
      call_incentive: row.call_incentive,
      time_incentive: row.time_incentive,
      sale_incentive: row.sale_incentive,
      fixed_salary: row.fixed_salary,
      variable_salary: row.variable_salary,
    });
  };
  const editUserTarget = useEditTarget();
  const handleSave = async () => {
    await editUserTarget(editedValues);
    // await getTableData(formData);
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleCancel = () => {
    // Cancel edit mode
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const headerStyle = {
    backgroundColor: "#1976d2",
    color: "white",
    textAlign: "center",
    fontSize: "14px",
    padding: "8px",
  };

  const cellStyle = {
    textAlign: "center",
    padding: "8px",
    fontSize: "14px",
    backgroundColor: "#f0f7ff",
  };
  return (
    <>
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        <Sidebar />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            marginTop: "1rem",
            marginLeft: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                // setValue(4)
                navigate("/home/performanceMgt");
              }}
            >
              <ArrowBackIcon sx={{ color: "#3E3E3E" }} />
            </IconButton>
            <Typography sx={{ color: "#3E3E3E", fontWeight: 700 }}>
              Coin Management
            </Typography>
            <Typography sx={{ color: "#3E3E3E", fontWeight: 700 }}></Typography>
            <Typography sx={{ color: "#3E3E3E", fontWeight: 400 }}>
              Details
            </Typography>
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8rem",
              flexWrap: "wrap",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  select
                  name="user_name"
                  size="small"
                  value={formData?.user_name}
                  onChange={handleChange}
                  variant="outlined"
                  style={{
                    maxWidth: "180px",
                  }}
                >
                  {srList?.map((sr) => (
                    <MenuItem key={sr.user_id} value={sr.first_name}>
                      {sr.first_name}
                    </MenuItem>
                  ))}
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    // label="June 2024"
                    name="monthYear"
                    views={["year", "month"]}
                    value={formData?.monthYear}
                    onChange={(date) => handleDateChange(date, "monthYear")}
                    size="small"
                    // sx={{ minWidth: 200 }}
                    sx={{
                      maxWidth: 190,
                      "& .MuiInputBase-root": {
                        height: "2.5rem",
                        fontSize: "0.875rem",
                      },
                      "& .MuiInputLabel-root": {
                        top: "-10px",
                        color: "#989797",
                      },
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    name="start_date"
                    value={formData?.start_date}
                    onChange={(date) => handleDateChange(date, "start_date")}
                    size="small"
                    sx={{
                      maxWidth: 180,
                      "& .MuiInputBase-root": {
                        height: "2.5rem",
                        fontSize: "0.875rem",
                      },
                      "& .MuiInputLabel-root": {
                        top: "-10px",
                        color: "#989797",
                      },
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    value={formData?.end_date}
                    onChange={(date) => handleDateChange(date, "end_date")}
                    size="small"
                    sx={{
                      maxWidth: 180,
                      "& .MuiInputBase-root": {
                        height: "2.5rem",
                        fontSize: "0.875rem",
                      },
                      "& .MuiInputLabel-root": {
                        top: "-10px",
                        color: "#989797",
                      },
                    }}
                  />
                </LocalizationProvider>
                {/* <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    MTD Working days
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    :
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 700, color: "#0C8709" }}
                  >
                    19
                  </Typography>
                  <Typography>|</Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    MTD Working days
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    :
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 700, color: "#000000" }}
                  >
                    28
                  </Typography>
                </Stack> */}
              </Box>

              <SingleSRExcelExport sx={{ marginRight: "0.8rem" }} />
            </Stack>

            <TableContainer style={{ maxHeight: 700, minWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ backgroundColor: "#F8F8F8" }}>
                  <TableRow style={{ height: "10px" }}>
                    {headerName?.map((header) => (
                      <TableCell
                        key={header?.index}
                        style={{
                          textAlign: "left",
                          width: "200px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                      >
                        {header?.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.single_sr_coin_details?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={cellStyle}>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell sx={cellStyle}>{row.date}</TableCell>{" "}
                      {/* Displaying the date */}
                      {/* Fixed Salary Column */}
                      <TableCell sx={cellStyle}>
                        <Stack
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.2rem",
                          }}
                        >
                          {row.fixed_salary} {/* Fixed salary */}
                          <img src={coinImage} style={{ width: "1rem" }} />
                        </Stack>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ visibility: "hidden" }}
                        >
                          {row.fixed_salary}
                        </Typography>
                      </TableCell>
                      {/* Call Incentive Coins */}
                      <TableCell sx={cellStyle}>
                        <Stack alignItems="center">
                          <Stack
                            alignItems="center"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0.2rem",
                            }}
                          >
                            {row.call_stamp_coins} {/* Call incentive coins */}
                            <img src={coinImage} style={{ width: "1rem" }} />
                          </Stack>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ width: "80px" }}
                          >
                            {row.call_achieved} / {row.total_call_target} Calls
                          </Typography>
                        </Stack>
                      </TableCell>
                      {/* Time Incentive Coins */}
                      <TableCell sx={cellStyle}>
                        <Stack alignItems="center">
                          <Stack
                            alignItems="center"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0.2rem",
                            }}
                          >
                            {row.time_stamp_coins} {/* Time incentive coins */}
                            <img src={coinImage} style={{ width: "1rem" }} />
                          </Stack>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ width: "100px" }}
                          >
                            {row.time_achieved} / {row.total_time_target} hrs
                          </Typography>
                        </Stack>
                      </TableCell>
                      {/* Sales Stats */}
                      <TableCell sx={cellStyle}>
                        <Stack alignItems="center">
                          <Stack
                            alignItems="center"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0.2rem",
                            }}
                          >
                            {row.sales_stamp_coins}{" "}
                            {/* Sales incentive coins */}
                            <img src={coinImage} style={{ width: "1rem" }} />
                          </Stack>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ width: "120px" }}
                          >
                            {row.sales_achieved} / {row.total_sales_target} Rs
                          </Typography>
                        </Stack>
                      </TableCell>
                      {/* Variable Salary */}
                      <TableCell sx={cellStyle}>
                        <Stack
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.2rem",
                          }}
                        >
                          {row.variable_salary_coins} {/* Variable salary */}
                          <img src={coinImage} style={{ width: "1rem" }} />
                        </Stack>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ visibility: "hidden" }}
                        >
                          {row.variable_salary_coins}
                        </Typography>
                      </TableCell>
                      {/* Petrol Allowance */}
                      <TableCell sx={cellStyle}>
                        <Stack
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.2rem",
                          }}
                        >
                          {row.petrol_coins}
                          <img src={coinImage} style={{ width: "1rem" }} />
                        </Stack>
                        <Typography variant="caption" color="textSecondary">
                          {row.petrol_allowance} km
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ visibility: "hidden" }}
                        >
                          {row.incentives_sum}
                        </Typography>
                      </TableCell>
                      {/* Call Incentive */}
                      <TableCell sx={cellStyle}>
                        <Stack
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.2rem",
                          }}
                        >
                          {row.call_incentive_calls_coins}
                          <img src={coinImage} style={{ width: "1rem" }} />
                        </Stack>
                        <Typography variant="caption" color="textSecondary">
                          {row.call_incentive_calls} calls
                        </Typography>
                      </TableCell>
                      {/* Sales Incentive */}
                      <TableCell sx={cellStyle}>
                        <Stack
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.2rem",
                          }}
                        >
                          {row.sales_incentive_sales_coins}
                          <img src={coinImage} style={{ width: "1rem" }} />
                        </Stack>
                        <Typography variant="caption" color="textSecondary">
                          {row.sales_incentive_cost} Rs
                        </Typography>
                      </TableCell>
                      {/* Incentives Sum */}
                      <TableCell sx={cellStyle}>
                        <Stack
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.2rem",
                          }}
                        >
                          {row.incentives_sum} {/* Incentives sum */}
                          <img src={coinImage} style={{ width: "1rem" }} />
                        </Stack>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ visibility: "hidden" }}
                        >
                          {row.incentives_sum}
                        </Typography>
                      </TableCell>
                      {/* Coins Earned */}
                      <TableCell sx={cellStyle}>
                        <Stack
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.2rem",
                          }}
                        >
                          {row.coins_earned} {/* Coins earned */}
                          <img src={coinImage} style={{ width: "1rem" }} />
                        </Stack>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ visibility: "hidden" }}
                        >
                          {row.coins_earned}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={data?.single_sr_coin_details?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default CoinManagementDetails;
