import React, { useState } from "react";
import Styles from "./styles/login.module.css";
import { Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import useSentOtp from "../../hooks/ForgotPassword/useSentOtp";
import { toast } from "react-toastify";
import useResetPassword from "../../hooks/ForgotPassword/useResetPassword";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#F2843F",
  "&:hover": {
    backgroundColor: "#F2843F",
  },
}));

const ResetPassword = ({ setIsResetPassword, step, setStep }) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState({});

  const handleBackToLogin = () => {
    setStep("email")
    setIsResetPassword(false);
  };

  const isValid = () => {
    let success = true;
    let err = {};

    if (!form?.otp?.trim()) {
      err.otp = "OTP is mandatory to reset password";
      success = false;
    }

    if (!form?.new_password?.trim()) {
      err.new_password = "New Password cannot be empty";
      success = false;
    }
    if (!form?.confirm_password?.trim()) {
      err.confirm_password = "Confirm Password cannot be empty";
      success = false;
    }

    setError(err);
    return success;
  };
  const resetPassword= useResetPassword()
  const handleSubmit = async() => {
    if (isValid()) {
      const param={
        new_password: form?.new_password,
        confirm_password: form?.confirm_password,
        email_otp: form?.otp,
        email: form?.email
      }
      const result=await resetPassword(param)
      if(result.code===200){
        toast.success("Password reset successfully!")
        setStep("email")
        setIsResetPassword(false)
      }else{
        toast.error(result.message)
      }
    }
  };

  const validateEmail = () => {
    let isValid = true;
    if (!form.email) {
      setError(prev => ({
        ...prev,
        email: 'Email is required'
      }));
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      setError(prev => ({
        ...prev,
        email: 'Please enter a valid email'
      }));
      isValid = false;
    }
    return isValid;
  };

  const sentOtp = useSentOtp();
  const handleSendOtp = async () => {
    if (validateEmail()) {
      try {
        const result= await sentOtp({ email: form.email });
        if(result?.code===200){
          toast.success("Otp Sent Successfully!")
          setStep("verify-otp");
        }
      } catch (error) {
        setError((prev) => ({
          ...prev,
          email: "Failed to send OTP. Please try again.",
        }));
      }
    }
  };

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  return step === "email" ? (
    <div className={Styles.list}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.2rem",
        }}
      >
        <div className={Styles.TextContainer}>
          <input
            placeholder="Enter Email Id"
            name="email"
            type="text"
            className={Styles.TextField}
            style={{ width: "14rem" }}
            onChange={handleChange}
          />
        </div>
        {error.email && (
          <label className={Styles.errorMessage}>
            {"**" + error.email + "**"}
          </label>
        )}
      </Stack>
      <div>
        <RedButton variant="contained" onClick={handleSendOtp}>
          send otp
        </RedButton>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "center",
        padding: "1.6rem",
      }}
    >
      <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <label className={Styles.NumberTitle}>
          Enter the OTP sent to your email id
        </label>
        <div className={Styles.TextContainer}>
          <input
            placeholder="Enter OTP"
            name="otp"
            type="number"
            className={Styles.TextField}
            style={{ width: "14rem" }}
            onChange={handleChange}
          />
        </div>
        {error.otp && (
          <label className={Styles.errorMessage}>
            {"**" + error.otp + "**"}
          </label>
        )}
      </Stack>

      <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <label className={Styles.NumberTitle}>Enter new password</label>
          <div className={Styles.TextContainer}>
            <input
              placeholder="New Password"
              name="new_password"
              type="text"
              className={Styles.TextField}
              style={{ width: "14rem" }}
              onChange={handleChange}
            />
          </div>
          {error.new_password && (
            <label className={Styles.errorMessage}>
              {"**" + error.new_password + "**"}
            </label>
          )}
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <label className={Styles.NumberTitle}>Enter confirm password</label>

          <div className={Styles.TextContainer}>
            <input
              placeholder="Confirm Password"
              name="confirm_password"
              type="text"
              className={Styles.TextField}
              style={{ width: "14rem" }}
              onChange={handleChange}
            />
          </div>
          {error.confirm_password && (
            <label className={Styles.errorMessage}>
              {"**" + error.confirm_password + "**"}
            </label>
          )}
        </Stack>

        <Stack sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div style={{ marginTop: "0.6rem" }}>
            <RedButton variant="contained" onClick={handleSubmit}>
              Submit
            </RedButton>
          </div>
          <Stack
            sx={{
              color: "#F2843F",
              textDecoration: "underline",
              fontSize: "15px",
              cursor: "pointer",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              display: "flex",
            }}
            variant="body2"
            onClick={handleBackToLogin}
          >
            Back to login
          </Stack>
        </Stack>
      </Stack>

      {/* <div className={Styles.TextContainer}>
          <input
            placeholder="Enter Email Id"
            name="email"
            type="text"
            className={Styles.TextField}
            style={{ width: "14rem" }}
            // onChange={handleChange}
          />
        </div>
        <div className={Styles.TextContainer}>
          <input
            placeholder="Enter Password"
            name="password"
            type="text"
            className={Styles.TextField}
            style={{ width: "14rem" }}
            // onChange={handleChange}
          />
        </div> */}
    </div>
  );
};

export default ResetPassword;
