import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEmployeeAreaList } from "../../slice/routeSlice";
import useAxiosPrivate from "../useAxiosPrivate";
import { getSingleSRCoinDetails, getSRCoinDetails } from "../../slice/srSlice";


const useSingleSRCoinDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSingleSRCoinDetails = async (param) => {
    
    const response = await Axios.post("single-sr-coin-details",param);
    const data = await response.data;
    dispatch(getSingleSRCoinDetails(data));
    return data.single_sr_coin_details
  };

  return getAllSingleSRCoinDetails
};

export default useSingleSRCoinDetails;
