import React, { createContext, useContext, useState } from 'react';

// Create a context for value and setValue
const ValueContext = createContext();

export const useValue = () => {
  return useContext(ValueContext);
};

export const ValueProvider = ({ children }) => {
  const [value, setValue] = useState(0);

  return (
    <ValueContext.Provider value={{ value, setValue }}>
      {children}
    </ValueContext.Provider>
  );
};
