import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBeatList, getEmployeeAreaList } from "../../slice/routeSlice";
import useAxiosPrivate from "../useAxiosPrivate";

const useSentOtp = () => {
  const Axios = useAxiosPrivate();
  const sentOtp = async (param) => {
    const response = await Axios.post("send_otp_email",param);
    const data = await response.data;
    return data
  };

  return sentOtp;
};

export default useSentOtp;
