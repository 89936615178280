import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { Link } from "react-router-dom";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AirlineStopsOutlinedIcon from "@mui/icons-material/AirlineStopsOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const location = useLocation();
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );
  const linkStyle = (paths) => ({
    color: paths.some((path) => location.pathname === path) ? "#fff" : "#fff", // Highlight exact matching paths
    cursor: "pointer",
    backgroundColor: paths.some((path) => location.pathname === path) ? "#717171": "#58585A",
    padding: "10px",
    borderRadius: "6px",
  });

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          top: "0",
          left: "0",
          marginLeft: "2px",
          marginTop: "4px",
          marginRight: "2px",
          backgroundColor: "#58585A", // Adjust the background color as needed
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "calc(100vh - 75px)",
          padding: "10px",
          borderRadius: "16px",
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          top: 0,
        }}
      >
        <Tooltip title="Dashboard">
          <Typography>
            <Link to="/home">
              <HomeOutlinedIcon fontSize="medium" sx={linkStyle(["/home", "/home/dashboardMapView", "/home/downloadDetails"])} />
            </Link>
          </Typography>
        </Tooltip>
        <Tooltip title="User Management">
          <Typography>
            <Link to="/home/usermgt">
              <PeopleAltOutlinedIcon fontSize="medium" sx={linkStyle(["/home/usermgt"])} />
            </Link>
          </Typography>
        </Tooltip>
        <Tooltip title="Category Management">
          <Typography>
            <Link to="/home/category">
              <CategoryOutlinedIcon fontSize="medium" sx={linkStyle(["/home/category"])} />
            </Link>
          </Typography>
        </Tooltip>
        <Tooltip title="Route Management">
          <Typography>
            <Link to="/home/routemgt">
              <AirlineStopsOutlinedIcon fontSize="medium" sx={linkStyle(["/home/routemgt"])} />
            </Link>
          </Typography>
        </Tooltip>
        <Tooltip title="Sales Management">
          <Typography>
            <Link to="/home/salesmgt">
              <PercentOutlinedIcon fontSize="medium" sx={linkStyle(["/home/salesmgt"])} />
            </Link>
          </Typography>
        </Tooltip>
        {userData?.role === "admin" ? 
        <Tooltip title="Schemes">
          <Typography>
            <Link to="/home/Schememgt">
              <SellOutlinedIcon fontSize="medium" sx={linkStyle(["/home/Schememgt", "/home/addScheme"])} />
            </Link>
          </Typography>
        </Tooltip>
        : null}
        <Tooltip title="Performance Management">
          <Typography>
            <Link to="/home/performanceMgt">
              <BarChartOutlinedIcon fontSize="medium" sx={linkStyle(["/home/performanceMgt"])} />
            </Link>
          </Typography>
        </Tooltip>
        <Typography
          sx={{
            fontSize: "12px !important",
            color: "#fff",
            position: "absolute",
            bottom: "10px",
            right:"10px"
          }}
        >

          v1.0.61

        </Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
