import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEmployeeAreaList } from "../../slice/routeSlice";
import useAxiosPrivate from "../useAxiosPrivate";
import { getSRCoinDetails } from "../../slice/srSlice";


const useSRCoinDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRCoinDetails = async (param) => {
    
    const response = await Axios.post("sr-coin-details",param);
    const data = await response.data;
    dispatch(getSRCoinDetails(data));
    return data.sr_coin_details
  };

  return getAllSRCoinDetails
};

export default useSRCoinDetails;
