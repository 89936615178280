import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import { getCoinMgtSRList, getSRList } from "../slice/srSlice";

const useCoinMgtSRList = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllCoinMgtSRList = async () => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
      };
    const response = await Axios.post("/coin_mgt_sr_list", param);
    console.log(response, "useCoinMgtSRList")
    const data = await response.data;
    dispatch(getCoinMgtSRList(data.body));
    }
  };

  return getAllCoinMgtSRList;
};

export default useCoinMgtSRList;
