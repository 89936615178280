import useAxiosPrivate from "../useAxiosPrivate";

const useResetPassword = () => {
  const Axios = useAxiosPrivate();
  const resetPassword = async (param) => {
    const response = await Axios.post("reset_password",param);
    const data = await response.data;
    return data
  };

  return resetPassword
};

export default useResetPassword;
