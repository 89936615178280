import { Button } from '@material-ui/core';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';

const ExcelExport = () => {
  const data = useSelector((state) => state.sr.srCoinDetails);

  const formatDataForExcel = () => {
    return data?.sr_coin_details?.flatMap((row) => [
      {
        "SR Name": row.sr_name,
        "Fixed Salary": row.fixed_salary,
        "Call Stamp Coins": `${row.call_stamp_coins} C`,
        "Time Stamp Coins": `${row.time_stamp_coins} C`,
        "Sales Stamp Coins": `${row.sales_stamp_coins} C`,
        "Variable Salary Coins": `${row.variable_salary_coins} C`,
        "Petrol Coins": `${row.petrol_coins} C`,
        "Call Incentive Coins": `${row.call_incentive_calls_coins} C`,
        "Sales Incentive Coins": `${row.sales_incentive_sales_coins} C`,
        "Incentives Sum": `${row.incentives_sum} C`,
        "Coins Earned": `${row.coins_earned} C`,
      },
      {
        "SR Name": ``,
        "Fixed Salary": ``,
        "Call Stamp Coins": `${row.call_achieved}/${row.total_call_target} Calls`,
        "Time Stamp Coins": `${row.time_achieved}/${row.total_time_target} hrs`,
        "Sales Stamp Coins": `${row.sales_achieved}/${row.total_sales_target} Rs`,
        "Variable Salary Coins": ``,
        "Petrol Coins": `${row.petrol_kms} kms`,
        "Call Incentive Coins": `${row.call_incentive_calls} call`,
        "Sales Incentive Coins": `${row.sales_incentive_cost} Rs`,
        "Incentives Sum": ``,
        "Coins Earned": ``,
      },
    ]);
  };
  const exportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // Define columns
    worksheet.columns = [
      { header: "SR Name", key: "SR Name", width: 20 },
      { header: "Fixed Salary", key: "Fixed Salary", width: 15 },
      { header: "Call Stamp Coins", key: "Call Stamp Coins", width: 20 },
      { header: "Time Stamp Coins", key: "Time Stamp Coins", width: 20 },
      { header: "Sales Stamp Coins", key: "Sales Stamp Coins", width: 20 },
      {
        header: "Variable Salary Coins",
        key: "Variable Salary Coins",
        width: 20,
      },
      { header: "Petrol Coins", key: "Petrol Coins", width: 15 },
      {
        header: "Call Incentive Coins",
        key: "Call Incentive Coins",
        width: 20,
      },
      {
        header: "Sales Incentive Coins",
        key: "Sales Incentive Coins",
        width: 20,
      },
      { header: "Incentives Sum", key: "Incentives Sum", width: 15 },
      { header: "Coins Earned", key: "Coins Earned", width: 15 },
    ];

    // worksheet.getCell('A').font = { bold: true };

    worksheet.spliceRows(1, 0, [], [], [], []);

    worksheet.mergeCells("C1:D1");
    worksheet.getCell("C1").value = `Month Oct'24`;
    worksheet.getCell("C1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("C1").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    worksheet.mergeCells("G1:K1");
    worksheet.getCell("G1").value =
      "Variable Salary Coins = Call Stamp Coins + Time Stamp Coins + Sales Stamp Coins";
    worksheet.getCell("G1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("G1").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    worksheet.mergeCells("C2:D2");
    worksheet.getCell("C2").value = `Total Working Days: ${data?.total_working_days} `;
    worksheet.getCell("C2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("C2").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    worksheet.mergeCells("G2:K2");
    worksheet.getCell("G2").value =
      "Earned Coins = Fixed Salary + Variable Salary Coins + Petrol Coins + Incentives Sum";
    worksheet.getCell("G2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("G2").font = { italic: true };

    worksheet.mergeCells("C3:D3");
    worksheet.getCell("C3").value = `Working Days Applicable: ${data?.working_days_applicable} `;
    worksheet.getCell("C3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("C3").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    worksheet.getRow(5).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF1E3A7D" },
      };
      cell.font = { color: { argb: "FFFFFFFF" }, bold: true };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Add data rows
    const formattedData = formatDataForExcel();

    formattedData?.forEach((row, index) => {
      const excelRow = worksheet.addRow(row);

      const isGroupEven = Math.floor(index / 2) % 2 === 0;

      excelRow.eachCell((cell, colNumber) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: isGroupEven ? "FFDDEBF7" : "FFB3C6E7" },
        };

        if (
          index % 2 !== 0 &&
          (colNumber === 3 ||
            colNumber === 4 ||
            colNumber === 5 ||
            colNumber === 8 ||
            colNumber === 9)
        ) {
          cell.font = { name: "Arial", size: 8 };
        }

        if (colNumber === 7) {
          cell.font = { bold: true };
          if (index % 2 !== 0 && colNumber === 7) {
            cell.font = { name: "Arial", size: 8 };
          }
        }

        if (
          colNumber === 1 ||
          colNumber === 2 ||
          colNumber === 6 ||
          colNumber === 10 ||
          colNumber === 11
        ) {
          cell.font = { bold: true };
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "SrMonthlyCoinData.xlsx");
  };

  return <Button onClick={exportExcel}>Export Excel</Button>;
};

export default ExcelExport;
