import { Button } from "@material-ui/core";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

// const data = [
//   {
//     date: "1-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 50,
//     total_call_target: 100,
//     time_stamp_coins: 633,
//     time_achieved: 4,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 5,
//     total_sales_target: 180,
//     variable_salary_coins: 434,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "0 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "0 Rs",
//     incentives_sum: 0,
//     coins_earned: 762,
//   },
//   {
//     date: "2-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 100,
//     total_call_target: 1200,
//     time_stamp_coins: 633,
//     time_achieved: 9,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 7,
//     total_sales_target: 180,
//     variable_salary_coins: 450,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "2 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "2k",
//     incentives_sum: 44,
//     coins_earned: 822,
//   },
//   {
//     date: "3-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 150,
//     total_call_target: 1200,
//     time_stamp_coins: 633,
//     time_achieved: 15,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 12,
//     total_sales_target: 180,
//     variable_salary_coins: 454,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "0 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "0 Rs",
//     incentives_sum: 0,
//     coins_earned: 782,
//   },
//   {
//     date: "4-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 200,
//     total_call_target: 1200,
//     time_stamp_coins: 633,
//     time_achieved: 22,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 20,
//     total_sales_target: 180,
//     variable_salary_coins: 477,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "0 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "0 Rs",
//     incentives_sum: 0,
//     coins_earned: 805,
//   },
//   {
//     date: "5-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 250,
//     total_call_target: 1200,
//     time_stamp_coins: 633,
//     time_achieved: 27,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 22,
//     total_sales_target: 180,
//     variable_salary_coins: 422,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "0 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "0 Rs",
//     incentives_sum: 0,
//     coins_earned: 750,
//   },
//   {
//     date: "6-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 300,
//     total_call_target: 1200,
//     time_stamp_coins: 633,
//     time_achieved: 35,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 29,
//     total_sales_target: 180,
//     variable_salary_coins: 432,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "0 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "0 Rs",
//     incentives_sum: 0,
//     coins_earned: 760,
//   },
//   {
//     date: "7-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 350,
//     total_call_target: 1200,
//     time_stamp_coins: 633,
//     time_achieved: 44,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 31,
//     total_sales_target: 180,
//     variable_salary_coins: 455,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "0 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "0 Rs",
//     incentives_sum: 0,
//     coins_earned: 783,
//   },
//   {
//     date: "8-Oct",
//     fixed_salary: 10,
//     call_stamp_coins: 870,
//     call_achieved: 400,
//     total_call_target: 1200,
//     time_stamp_coins: 633,
//     time_achieved: 56,
//     total_time_target: 125,
//     sales_stamp_coins: 1800,
//     sales_achieved: 35,
//     total_sales_target: 180,
//     variable_salary_coins: 421,
//     petrol_coins: 150,
//     petrol_allowance: "50 kms",
//     call_incentive_calls_coins: 0,
//     call_incentive_calls: "0 Call",
//     sales_incentive_sales_coins: 0,
//     sales_incentive_coins: "0 Rs",
//     incentives_sum: 0,
//     coins_earned: 749,
//   },
// ];
// Excel Export Button Component
const SingleSRExcelExport = () => {
  const data = useSelector((state) => state.sr.singleSRCoinDetails);

  const formatDataForExcel = () => {
    return data?.single_sr_coin_details?.flatMap((row) => [
      {
        Date: row.date,
        "Fixed Salary": row.fixed_salary,
        "Call Stamp Coins": `${row.call_stamp_coins} C`,
        "Time Stamp Coins": `${row.time_stamp_coins} C`,
        "Sales Stamp Coins": `${row.sales_stamp_coins} C`,
        "Variable Salary Coins": `${row.variable_salary_coins} C`,
        "Petrol Coins": `${row.petrol_coins} C`,
        "Call Incentive Coins": `${row.call_incentive_calls_coins} C`,
        "Sales Incentive Coins": `${row.sales_incentive_sales_coins} C`,
        "Incentives Sum": `${row.incentives_sum} C`,
        "Coins Earned": `${row.coins_earned} C`,
      },
      {
        Date: "",
        "Fixed Salary": "",
        "Call Stamp Coins": `${row.call_achieved}/${row.total_call_target} Calls`,
        "Time Stamp Coins": `${row.time_achieved}/${row.total_time_target} hrs`,
        "Sales Stamp Coins": `${row.sales_achieved}/${row.total_sales_target} Rs`,
        "Variable Salary Coins": "",
        "Petrol Coins": `${row.petrol_allowance} kms`,
        "Call Incentive Coins": `${row.call_incentive_calls} call`,
        "Sales Incentive Coins": `${row.sales_incentive_cost} Rs`,
        "Incentives Sum": "",
        "Coins Earned": "",
      },
    ]);
  };

  const exportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // Format headers
    const formattedData = formatDataForExcel();
    const headers = Object.keys(formattedData[0]);
    worksheet.columns = [
      { header: "Date", key: "Date", width: 20 },
      { header: "Fixed Salary", key: "Fixed Salary", width: 15 },
      { header: "Call Stamp Coins", key: "Call Stamp Coins", width: 20 },
      { header: "Time Stamp Coins", key: "Time Stamp Coins", width: 20 },
      { header: "Sales Stamp Coins", key: "Sales Stamp Coins", width: 20 },
      {
        header: "Variable Salary Coins",
        key: "Variable Salary Coins",
        width: 20,
      },
      { header: "Petrol Coins", key: "Petrol Coins", width: 15 },
      {
        header: "Call Incentive Coins",
        key: "Call Incentive Coins",
        width: 20,
      },
      {
        header: "Sales Incentive Coins",
        key: "Sales Incentive Coins",
        width: 20,
      },
      { header: "Incentives Sum", key: "Incentives Sum", width: 15 },
      { header: "Coins Earned", key: "Coins Earned", width: 15 },
    ];

    // Apply header styling
    worksheet.spliceRows(1, 0, [], [], [], [], []);

    worksheet.mergeCells("A2:B2");
    worksheet.getCell("A2").value = `Total Working Days: ${data?.total_working_days} `;
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A2").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    worksheet.mergeCells("D1:I1");
    worksheet.getCell("D1").value = "Monthly Targets ";
    worksheet.getCell("D1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("D1").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.mergeCells("A3:B3");
    worksheet.getCell("A3").value = `Working Days Applicable: ${data?.working_days_applicable} `;
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A3").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    worksheet.mergeCells("A1:B1");
    worksheet.getCell("A1").value = `SR Name: ${data?.sr_name}`;
    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A1").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    worksheet.mergeCells("A4:B4");
    worksheet.getCell("A4").value = `Month ${data?.month}'${data?.year}`;
    worksheet.getCell("A4").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A4").font = {
      italic: true,
      color: { argb: "FF333333" },
    };

    // worksheet.mergeCells("C2:D2");
    worksheet.getCell("C2").value = "Sale";
    worksheet.getCell("C2").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("C2").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getCell("D2").value = `${data?.total_sales_target} Rs`;
    worksheet.getCell("D2").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("D2").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("D2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    // worksheet.mergeCells("C3:D3");
    worksheet.getCell("C3").value = "Call";
    worksheet.getCell("C3").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("C3").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getCell("D3").value = `${data?.total_call_target}`;
    worksheet.getCell("D3").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("D3").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("D3").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    // worksheet.mergeCells("E2:F2");
    worksheet.getCell("E2").value = "Time";
    worksheet.getCell("E2").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("E2").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getCell("F2").value = `${data?.total_time_target} hrs`;
    worksheet.getCell("F2").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("F2").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("F2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    // worksheet.mergeCells("E3:F3");
    worksheet.getCell("E3").value = "Petrol";
    worksheet.getCell("E3").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("E3").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getCell("F3").value = `${data?.total_petrol_km_target}`;
    worksheet.getCell("F3").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("F3").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("F3").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    // worksheet.mergeCells("G2:H2");
    worksheet.getCell("G2").value = "Fixed Salary";
    worksheet.getCell("G2").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("G2").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getCell("H2").value = `${data?.fixed_salary_target}`;
    worksheet.getCell("H2").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("H2").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("H2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    // worksheet.mergeCells("G3:H3");
    worksheet.getCell("G3").value = "Variable Salary";
    worksheet.getCell("G3").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("G3").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getCell("H3").value = `${data?.variable_coins_target}`;
    worksheet.getCell("H3").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("H3").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("H3").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    // worksheet.mergeCells("I2:J2");
    worksheet.getCell("I2").value = "Call Incentive/Call";
    worksheet.getCell("I2").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("I2").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getColumn("I").width = 20;

    worksheet.getCell("J2").value = `${data?.call_incentive_per_call}`;
    worksheet.getCell("J2").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("J2").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("J2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    // worksheet.mergeCells("I3:J3");
    worksheet.getCell("I3").value = "Sale Incentive/Call";
    worksheet.getCell("I3").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("I3").font = {
      italic: true,
      color: { argb: "FF333333" },
      bold: true,
    };

    worksheet.getCell("J3").value = `${data?.sale_incentive_per_call}`;
    worksheet.getCell("J3").alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell("J3").font = {
      italic: true,
      color: { argb: "FF333333" },
    };
    worksheet.getCell("J3").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FEF2CB" },
    };

    worksheet.getRow(6).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF1E3A7D" },
      };
      cell.font = { color: { argb: "FFFFFFFF" }, bold: true };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Add rows with alternating colors
    formattedData.forEach((row, index) => {
      const excelRow = worksheet.addRow(row);
      const isGroupEven = Math.floor(index / 2) % 2 === 0;

      excelRow.eachCell((cell, colNumber) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: isGroupEven ? "E2EFD9" : "FFFFFFFF" },
        };

        if (
          index % 2 !== 0 &&
          (colNumber === 3 ||
            colNumber === 4 ||
            colNumber === 5 ||
            colNumber === 8 ||
            colNumber === 9)
        ) {
          cell.font = { name: "Arial", size: 8 };
        }

        if (colNumber === 7) {
          cell.font = { bold: true };
          if (index % 2 !== 0 && colNumber === 7) {
            cell.font = { name: "Arial", size: 8 };
          }
        }

        if (
          colNumber === 1 ||
          colNumber === 2 ||
          colNumber === 6 ||
          colNumber === 10 ||
          colNumber === 11
        ) {
          cell.font = { bold: true };
        }
      });
    });

    // Generate and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "SingleSrCoinData.xlsx");
  };

  return <Button onClick={exportExcel}>Export Excel</Button>;
};

export default SingleSRExcelExport;
